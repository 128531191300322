import React, { useEffect, useState } from 'react'
import { Flex, Space, Table, Typography } from 'antd'
import Link from 'antd/es/typography/Link'
import backendApi from '../api/backendApi'
import { GetPermissionsRequest, Permission } from '../interfaces/AppInterfaces'

const PermissionsPage = () => {

    const [permissions, setPermissions] = useState<Permission[]>()
    const [loading, setLoading] = useState(true)

    const columns =[
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Fecha de Creacion',
            dataIndex: 'created_at',
            key: 'created_at',
        } 
    ]

    const getPermissions = async ()=>{
        const apiCall = await backendApi.get<GetPermissionsRequest>('/getPermissions');
        if (apiCall.data.data) {
            setPermissions(apiCall.data.data[0]);
        }
        setLoading(false)
    }

    useEffect(() => {
        getPermissions()
    }, [])


  return (
    <Flex justify="center" vertical gap="middle">
        <Typography.Title>Permisos</Typography.Title>   
        <Table columns ={columns} dataSource={permissions} scroll={{ x: 1}} loading={loading}/>         
    </Flex>
  )
}

export default PermissionsPage
