import React, { useEffect, useState } from 'react'
import { Button, Divider, Flex, Form, Input, Modal, Space, Table, Tag, Typography } from 'antd'
import backendApi from '../api/backendApi'
import { GetUsersResponse, User, UserList } from '../interfaces/AppInterfaces'
import Link from 'antd/es/typography/Link'
import {PlusOutlined} from '@ant-design/icons';
import RegisterAdminUserModal from '../components/RegisterAdminUserModal'
import sirumatekLogo from '../assets/sirumatekLogo.png';

const UserPage = () => {
    
    const [users, setUsers] = useState<UserList[]>()
    const [loading, setLoading] = useState(true)
    const [registerUserModal, setRegisterUserModal] = useState<boolean>(false);
    const [deactivateModal, setDeactivateModal] = useState<boolean>(false);
    const [activateUserModal, setActivateUserModal] = useState<boolean>(false);
    const [resetPassworUserModal, setResetPasswordUserModal] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    const [selectedUser, setSelectedUser] = useState<User>()

    const columns =[
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Documento Identidad',
            dataIndex: 'doc_id',
            key: 'doc_id',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text:number) =>{
                switch (text) {
                    case 1:
                        return <Tag color='green'>Activo</Tag>
                        break;
                    case 2:
                        return <Tag color='red'>Inactivo</Tag>
                        break
                    default:
                        return <Tag color='grey'>N/A</Tag>
                        break;
                }
            } 
        },
        {
            title: 'Fecha Ingreso',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'Contacto',
            dataIndex: 'mobile_phone',
            key: 'mobile_phone',
        },     
        {
            title: 'Ultimo Inicio de Sesion',
            dataIndex: 'last_login',
            key: 'last_login',
            render: (text:string)=>{
                if (text!== null) {
                    return text;
                }
                else{
                    return 'No ha Iniciado Sesion'
                }
            }
        },    
        {
            title: 'Rol',
            dataIndex: 'rol',
            key: 'rol',
            render: (text:string) =>{
                switch (text) {
                    case 'Admin':
                        return <Tag color='blue'>{text}</Tag>
                        break;
                    case 'Manejador de Plataforma':
                        return <Tag color='volcano'>{text}</Tag>
                        break
                    case 'Emisor':
                        return <Tag color='orange'>{text}</Tag>
                        break
                    default:
                        return <Tag color='grey'>{text}</Tag>
                        break;
                }
            } 
        },
        {
            title: 'Action',
            key: 'action',
            //@ts-ignore
            render: (_, record) => (
              <Space size="middle">
                <Link style={{color:'red'}} onClick={()=>{ record.status === 1 ? deactivateUserModal(record): activateUser(record)}}>{record.status === 1 ? 'Inhabilitar':'Activar'}</Link>
                <Link onClick={()=>{changePasswordUser(record)}}>Reestablecer Contraseña</Link>
              </Space>
            ),
          },
    ]

    const getUsers = async ()=>{
        const apiCall = await backendApi.get<GetUsersResponse>('/getUsers');
        if (apiCall.data.data) {
            setUsers(apiCall.data.data[0]);
        }
        setLoading(false)
    }

    const registerUser = ()=>{
        return setRegisterUserModal(!registerUserModal)  
    }

    const deactivateUser = async () => {
        
    }

    const changePasswordUser = (user:User)=>{
        setSelectedUser(user)
        setResetPasswordUserModal(true)
    }

    const activateUser = (user:User)=>{
        setSelectedUser(user)
        setActivateUserModal(true)
    }

    const deactivateUserModal = (user:User)=>{
        setSelectedUser(user)
        setDeactivateModal(true)
    }



    useEffect(() => {
        getUsers()
    }, [])
    
  return (
    <Flex justify="center" vertical gap="middle">
        <Flex style={{width:'100%'}} justify='space-between' >
            <Typography.Title>Usuarios</Typography.Title>
            <Button type="primary" style={{background:'#47b5bd', alignSelf:'center',width:200}} onClick={registerUser}>
                <PlusOutlined /> Nuevo Usuario
            </Button>
        </Flex>
        <Table columns ={columns} dataSource={users} scroll={{ x: 1}} loading={loading}/>
        <RegisterAdminUserModal isVisible={registerUserModal} isUserRegister={()=>setRegisterUserModal}/>
        
        <Modal open={deactivateModal} footer={null}  style={{ top: 20 }} width={900} closable={false}>
            <Flex align="middle" justify="center" vertical>
                <Flex align="middle" justify="center" vertical>
                    <img
                        src={sirumatekLogo}
                        width={238}
                        height={42}
                        alt="Conpany Logo"
                        style={{alignSelf:'center'}}
                        />
                    <Typography.Text style={{fontSize:14, color: 'rgba(0, 0, 0, 0.4)',alignSelf:'center',marginTop:5}}>Sistema de Imprenta Digital </Typography.Text>
                </Flex>
                <Typography.Text style={{fontSize:18, fontWeight:'bold'}}>Inhabilitar Usuario</Typography.Text>  
                <Divider/>
                    <Form layout='vertical' style={{width:'100%'}} onFinish={deactivateUser}>
                        <Flex align="middle" justify='center' style={{marginTop:5,marginBottom:30}}>
                        <Form.Item label="Confirmar" name="confirm" rules={[{ required: true, message: 'Ingrese la plabra confirmar'},{pattern:RegExp("\bConfirmar\b")}]} style={{width:'100%'}}>
                            <Input size="large" placeholder="Confirmar"/>
                        </Form.Item>
                        </Flex>  
                            <Flex align="middle" justify='space-between' >
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" style={{background:'#47b5bd'}}>
                                        Inhabilitar
                                    </Button>
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" style={{background:'#ff4d4f'}} onClick={()=>setDeactivateModal(false)}>
                                        Cancelar
                                    </Button>
                                </Form.Item>
                            </Flex>
                    </Form>
                </Flex>
        </Modal>
        <Modal open={activateUserModal} footer={null}  style={{ top: 20 }} width={900} closable={false}>
            <Flex align="middle" justify="center" vertical>
                <Flex align="middle" justify="center" vertical>
                    <img
                        src={sirumatekLogo}
                        width={238}
                        height={42}
                        alt="Conpany Logo"
                        style={{alignSelf:'center'}}
                        />
                    <Typography.Text style={{fontSize:14, color: 'rgba(0, 0, 0, 0.4)',alignSelf:'center',marginTop:5}}>Sistema de Imprenta Digital </Typography.Text>
                </Flex>
                <Typography.Text style={{fontSize:18, fontWeight:'bold'}}>Habilitar Usuario</Typography.Text>  
                <Divider/>
                    <Form layout='vertical' style={{width:'100%'}} onFinish={deactivateUser}>
                        <Flex align="middle" justify='center' style={{marginTop:5,marginBottom:30}}>
                        <Form.Item label="Confirmar" name="confirm" rules={[{ required: true, message: 'Por favor Confirme la Activacion del Usuario'},{pattern:RegExp("/b Confirmar /b ")}]} style={{width:'100%'}}>
                            <Input size="large" placeholder="Confirmar"/>
                        </Form.Item>
                        </Flex>  
                            <Flex align="middle" justify='space-between' >
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" style={{background:'#47b5bd'}}>
                                        Habilitar Usuario
                                    </Button>
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" style={{background:'#ff4d4f'}} onClick={()=>setActivateUserModal(false)}>
                                        Cancelar
                                    </Button>
                                </Form.Item>
                            </Flex>
                    </Form>
                </Flex>
        </Modal>
        <Modal open={resetPassworUserModal} footer={null}  style={{ top: 20 }} width={900} closable={false}>
            <Flex align="middle" justify="center" vertical>
                <Flex align="middle" justify="center" vertical>
                    <img
                        src={sirumatekLogo}
                        width={238}
                        height={42}
                        alt="Conpany Logo"
                        style={{alignSelf:'center'}}
                        />
                    <Typography.Text style={{fontSize:14, color: 'rgba(0, 0, 0, 0.4)',alignSelf:'center',marginTop:5}}>Sistema de Imprenta Digital </Typography.Text>
                </Flex>
                <Typography.Text style={{fontSize:18, fontWeight:'bold'}}>Cambiar Contraseña</Typography.Text>  
                <Divider/>
                    <Form layout='vertical' style={{width:'100%'}} onFinish={deactivateUser}>
                        <Flex align="middle" justify='center' style={{marginTop:5,marginBottom:30}}>
                            <Form.Item label="Contraseña" name="password" rules={[{ required: true, message: 'Ingrese la Contraseña' }]} style={{width:'100%'}}>
                                <Input size="large" placeholder="Contraseña" onChange={e => setPassword(e.target.value)}  type='password' />
                            </Form.Item>
                        </Flex>  
                        <Flex align="middle" justify='center' style={{marginTop:5,marginBottom:30}}>
                            <Form.Item label="Confirmar Contraseña" name="oficiallName" 
                                rules={[
                                    { required: true, message: 'Confirme su Contraseña' },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Las Contraseñas Ingresadas no Coinciden!'));
                                        },
                                    }),
                                ]} 
                                style={{width:'100%'}}>
                                <Input size="large" placeholder="Confirmar Contraseña" type='password'/>
                            </Form.Item>
                        </Flex>
                        <Flex align="middle" justify='space-between' >
                            <Form.Item>
                                <Button type="primary" htmlType="submit" style={{background:'#47b5bd'}}>
                                    Reestablecer Contraseña
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" style={{background:'#ff4d4f'}} onClick={()=>setResetPasswordUserModal(false)}>
                                    Cancelar
                                </Button>
                            </Form.Item>
                        </Flex>
                    </Form>
                </Flex>
        </Modal>
    </Flex>
  )
}

export default UserPage
