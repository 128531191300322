import { Button, DatePicker, Divider, Flex, Form, Modal, Space, Table, Typography } from 'antd'
import Link from 'antd/es/typography/Link'
import React, { useEffect, useState } from 'react'
import backendApi from '../api/backendApi'
import { Backups, GetBackupsRequest } from '../interfaces/AppInterfaces'
import { PlusOutlined } from '@ant-design/icons';
import sirumatekLogo from '../assets/sirumatekLogo.png';


const BackupsPage = () => {
 
    const [backups, setBackups] = useState<Backups[]>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [newBackupModal, setNewBackupModal] = useState<boolean>(false)
    const [fromDate, setFromDate] = useState<string>('')
    const [toDate, setToDate] = useState<string>('')

    const columns = [
      {
        title: 'Cantidad de Documentos Respaldados',
        dataIndex: 'num_docs',
        key: 'num_docs',
      },
      {
        title: 'Fecha Inicial del Rango',
        dataIndex: 'from_date',
        key: 'from_date',
      },
      {
        title: 'Fecha Final del Rango',
        dataIndex: 'to_date',
        key: 'to_date',
      },
      {
        title: 'Action',
        key: 'action',
        //@ts-ignore
        render: (_, record) => (
          <Space size="middle">
            <Link onClick={()=>downloadBackup(record.from_date,record.to_date)}>Ver Respaldo</Link>
          </Space>
        ),
    },
    ]

    const getBackups = async () => {
      const getBackupsRequest = await backendApi.get<GetBackupsRequest>('/getAllBackups')
      setBackups(getBackupsRequest.data.data[0])
      setIsLoading(false)
    }

    const downloadBackup = async (from_date:string,to_date:string) => {
      
      const response = await backendApi.post("getBackup",{from_date,to_date},{responseType: 'blob'})
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      const fileName = from_date.replace('/', '_') + '_to_' + to_date + '.zip';
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    }
    
    const generateBackup = async () => {
      setIsLoading(true)
      const data = {
        from_date:fromDate,
        to_date:toDate
      }
      const generatetBackupRequest = await backendApi.post('/dowloadBackup',data,{responseType: 'blob'});
      const url = window.URL.createObjectURL(new Blob([generatetBackupRequest.data]));
      const link = document.createElement('a');
      const fileName = fromDate.replace('-', '_') + '_to_' + toDate + '.zip';
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      setIsLoading(false)
      setNewBackupModal(false)
      setFromDate('')
      setToDate('')
    }


    const dateTimeOnChangeFrom = (
      date: any | null,
      dateString: any
    ): void => {
      
      setFromDate(dateString);
    };

    const dateTimeOnChangeTo = (
      date: any | null,
      dateString: any
    ): void => {
      
      setToDate(dateString);
    };


    useEffect(() => {
        getBackups()
    }, [])
    
  return (
    <Flex justify="center" vertical gap="middle">
       <Flex style={{width:'100%'}} justify='space-between' >
            <Typography.Title>Respaldos </Typography.Title>  
            <Button type="primary" style={{background:'#47b5bd', alignSelf:'center',width:200}} onClick={()=>setNewBackupModal(true)}>
                <PlusOutlined /> Nuevo Respaldo
            </Button>  
       </Flex>
      
      <Table columns ={columns} dataSource={backups} scroll={{ x: 1}} loading={isLoading}/>

      <Modal open={newBackupModal} footer={null}  style={{ top: 20 }} width={900} onCancel={()=>setNewBackupModal(false)}>
       <Flex align="middle" justify="center" vertical>
                <Flex align="middle" justify="center" vertical>
                    <img
                        src={sirumatekLogo}
                        width={238}
                        height={42}
                        alt="Conpany Logo"
                        style={{alignSelf:'center'}}
                        />
                    <Typography.Text style={{fontSize:14, color: 'rgba(0, 0, 0, 0.4)',alignSelf:'center',marginTop:5}}>Sistema de Imprenta Digital </Typography.Text>
                </Flex>
                    <Typography.Text style={{fontSize:18, fontWeight:'bold'}}>Crear Backup</Typography.Text>  
                    <Divider/>
                    <Form layout='vertical' style={{width:'100%'}} onFinish={generateBackup}>
                        <Form.Item label="Fecha de Inicio" name="fromDate" rules={[{ required: true, message: 'Ingrese La Fecha de Inicio' }]} style={{width:'100%'}}>
                          <DatePicker  onChange={dateTimeOnChangeFrom} needConfirm />
                        </Form.Item>
                        <Form.Item label="Fecha Final" name="toDate" rules={[{ required: true, message: 'Ingrese La Fecha Final' }]} style={{width:'100%'}}>
                          <DatePicker  onChange={dateTimeOnChangeTo} needConfirm />
                        </Form.Item>
                        <Flex align="middle" justify='space-between' >
                            <Form.Item>
                                <Button type="primary" htmlType="submit" style={{background:'#47b5bd'}}>
                                    Descargar Backup
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" style={{background:'#ff4d4f'}} onClick={()=>setNewBackupModal(false)}>
                                    Cancelar
                                </Button>
                            </Form.Item>
                    </Flex>
                    </Form>
            </Flex>
       </Modal>
    </Flex>
  )
}

export default BackupsPage
