import React, { useEffect, useState } from 'react'
import { Button, Divider, Flex, Form, Input, Modal, Space, Table, Typography } from 'antd'
import Link from 'antd/es/typography/Link'
import backendApi from '../api/backendApi'
import { FrownOutlined, PlusOutlined } from '@ant-design/icons';
import sirumatekLogo from '../assets/sirumatekLogo.png';

const NotificationPage = () => {

    const [notifications, setNotifications] = useState()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [newNotificationModal, setNewNotificationModal] = useState<boolean>(false)
    const [name, setName] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    
    const columns = [
      {
        title: 'Nombre',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Cantidad de Usuarios Notificados',
        dataIndex: 'num_notificated',
        key: 'num_notificated',
      },
      {
        title: 'Usuario Notificado',
        dataIndex: 'recipient',
        key: 'recipient',
      },
      {
        title: 'Action',
        key: 'action',
        //@ts-ignore
        render: (_, record) => (
          <Space size="middle">
            <Link>Ver Notificacion</Link>
          </Space>
        ),
    },
    ]

    const getNotifications = async () => {
      const getNotificationRequest = await backendApi.get('/getAllNotifications')
      setIsLoading(false)
    }

    const sendNotification = async () => {
      
    }


    useEffect(() => {
      getNotifications()
    }, [])
    
  return (
    <Flex justify="center" vertical gap="middle">
      <Flex style={{width:'100%'}} justify='space-between' >
        <Typography.Title>Notificaciones</Typography.Title>
        <Button type="primary" style={{background:'#47b5bd', alignSelf:'center',width:200}} onClick={()=>setNewNotificationModal(true)}>
                <PlusOutlined /> Nueva Notificacion
        </Button>   
      </Flex>
      <Table columns ={columns} dataSource={notifications} scroll={{ x: 1}} loading={isLoading}/>
      <Modal open={newNotificationModal} footer={null}  style={{ top: 20 }} width={900} onCancel={()=>setNewNotificationModal(false)}>
      <Flex align="middle" justify="center" vertical>
                <Flex align="middle" justify="center" vertical>
                    <img
                        src={sirumatekLogo}
                        width={238}
                        height={42}
                        alt="Conpany Logo"
                        style={{alignSelf:'center'}}
                        />
                    <Typography.Text style={{fontSize:14, color: 'rgba(0, 0, 0, 0.4)',alignSelf:'center',marginTop:5}}>Sistema de Imprenta Digital </Typography.Text>
                </Flex>
                    <Typography.Text style={{fontSize:18, fontWeight:'bold'}}>Nueva Notificacion</Typography.Text>  
                    <Divider/>
                    <Form layout='vertical' style={{width:'100%'}} onFinish={sendNotification}>
                        <Form.Item label="Titulo de la Notificacion" name="name" rules={[{ required: true, message: 'Ingrese el Titulo de la Notificacion' }]} style={{width:'100%'}}>
                            <Input size="large" placeholder="Titulo de la Notificacion" onChange={e => setName(e.target.value)}/>
                        </Form.Item>
                        <Form.Item label="Mensaje" name="message" rules={[{ required: true, message: 'Ingrese el Mensaje' }]} style={{width:'100%'}}>
                            <Input size="large" placeholder="Descripcion del Token" onChange={e => setDescription(e.target.value)}/>
                        </Form.Item>

                        <Flex align="middle" justify='space-between' >
                            <Form.Item>
                                <Button type="primary" htmlType="submit" style={{background:'#47b5bd'}}>
                                    Notificar
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" style={{background:'#ff4d4f'}} onClick={()=>setNewNotificationModal(false)}>
                                    Cancelar
                                </Button>
                            </Form.Item>
                    </Flex>
                    </Form>
            </Flex>
      </Modal>
    </Flex>
  )
}

export default NotificationPage
