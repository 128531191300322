import React, { useEffect, useState } from 'react'
import { Alert, Button, Divider, Flex, Form, Input, Modal, notification, Select, Typography } from 'antd'
import sirumatekLogo from '../assets/sirumatekLogo.png';
import { useNavigate } from 'react-router-dom';
import backendApi from '../api/backendApi';
import { GetRolesRequest, Role } from '../interfaces/AppInterfaces';
import {PlusOutlined, FrownOutlined} from '@ant-design/icons';

interface Props{
    isVisible:boolean;
    isUserRegister: (val:boolean)=>{}
}

const RegisterAdminUserModal = ({isVisible, isUserRegister}:Props) => {
    
    const [show, setShow] = useState<boolean>(isVisible);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [docId, setDocId] = useState<string>('');
    const [mobilePhone, setMobilePhone] = useState<string>('');
    const [docTypeUser, setdocTypeUser] = useState<string>('J');
    const [roles, setRoles] = useState<Role[]>()

    const [selectedRole, setSelectedRole] = useState('')

    const [api, contextHolder] = notification.useNotification();
    const [registerNewUserForm] = Form.useForm();
    const navigate = useNavigate();
    
    const handleCancel = ()=>{
        setShow(false);
    }

    const saveUser = async () => {
        setIsLoading(true)
        const data = {
            name: name+' '+lastName,
            email:email,
            password:password,
            doc_id:docTypeUser+docId,
            mobile_phone:mobilePhone,
            role:selectedRole,
        }
        if (selectedRole === 'Emisor') {
            errrorOnRole()
            setIsLoading(false);
            return 
        }
        const response = backendApi.post('/registerPlatformUser',data).then(elm=>{
            openSuccesNotification();
            setIsLoading(false);
            setShow(false);
            registerNewUserForm.resetFields();
        }).catch(err=>{
            openErrorNotification(err)
        })
    }

    const openSuccesNotification = () => {
        api.open({
          message: 'Se Registro Con Exito el Usuario',
          description:
            'Se ha registrado con exito el usuario',
          icon: <PlusOutlined style={{ color: '#47b5bd' }} />,
          onClick: ()=>navigate(0)
        });
      };
    
    const openErrorNotification = (err:JSON) => {
        console.log(err);
        const errors = JSON.stringify(err);
        api.open({
          message: 'No se Registro el Usuario',
          description:
            'Ha ocurrido un Error:  '+errors,
          icon: <FrownOutlined style={{ color: '#47b5bd' }} />,
        });
      };


      const errrorOnRole = () => {
        
        api.open({
          message: 'No es Posible Registrar Emisor',
          description:'Dirijase al Modulo de Emisores Para Registrar',
          icon: <FrownOutlined style={{ color: '#47b5bd' }} />,
        });
      };

    const prefixSelectorUser = (
        <Form.Item name="prefixUser" noStyle>
        <Select style={{ width: 70 }} onChange={e=>setdocTypeUser(e)} defaultValue={"J"}>
            <Select.Option value="V" >V</Select.Option>
            <Select.Option value="E">E</Select.Option>
            <Select.Option value="J">J</Select.Option>
            <Select.Option value="P">P</Select.Option>
            <Select.Option value="G">G</Select.Option>
        </Select>
        </Form.Item>
    );

    const getRoles = async ()=>{
        const apiCall = await backendApi.get<GetRolesRequest>('/getRoles');
        if (apiCall.data.data) {
            setRoles(apiCall.data.data[0]);
        }
    }

    useEffect(() => {
        setShow(isVisible);
        getRoles()
    }, [isVisible])  

    return (
    <>
        {contextHolder}
      <Modal open={show} footer={null}  style={{ top: 20 }} width={900} onCancel={handleCancel}>
      <Flex align="middle" justify="center" vertical>
                <Flex align="middle" justify="center" vertical>
                    <img
                        src={sirumatekLogo}
                        width={238}
                        height={42}
                        alt="Conpany Logo"
                        style={{alignSelf:'center'}}
                        />
                    <Typography.Text style={{fontSize:14, color: 'rgba(0, 0, 0, 0.4)',alignSelf:'center',marginTop:5}}>Sistema de Imprenta Digital </Typography.Text>
                </Flex>
                    <Typography.Text style={{fontSize:18, fontWeight:'bold'}}>Registrar Usuario</Typography.Text>  
                    <Divider/>   
            </Flex>
            <Flex align="middle" justify="center" vertical>
                <Form layout='vertical' onFinish={saveUser} style={{width:'100%'}} disabled={isLoading} form={registerNewUserForm}>
                    
                    <Form.Item label="Nombre" name="name" rules={[{ required: true, message: 'Ingrese el Nombre del Responsable' }]} style={{width:'100%'}}>
                        <Input size="large" placeholder="Nombre" onChange={e => setName(e.target.value)}/>
                    </Form.Item>

                    <Form.Item label="Apellido" name="lastName" rules={[{ required: true, message: 'Ingrese el Apellido del Responsable' }]} style={{width:'100%'}}>
                        <Input size="large" placeholder="Apellido" onChange={e => setLastName(e.target.value)}/>
                    </Form.Item>

                    <Form.Item label="Documento de Identidad" name="cedula" rules={[{ required: true, message: 'Ingrese el Rif' }]} style={{width:'100%'}}>
                        <Input addonBefore={prefixSelectorUser} size="large" placeholder="Documento de Identidad" onChange={e => setDocId(e.target.value)} pattern='^[0-9]*$' maxLength={10}/>
                    </Form.Item>
                
                    <Form.Item label="Telefono" name="mobilePhone" rules={[{ required: true, message: 'Ingrese el Telefono de Contacto del Responsable' }]} style={{width:'100%'}}>
                        <Input size="large" placeholder="Telefono de Contacto" onChange={e => setMobilePhone(e.target.value)} pattern='^[0-9]*$' maxLength={11}/>
                    </Form.Item>

                    <Form.Item name="userRole" label='Rol del Usuario' rules={[{ required: true, message: 'Seleccione un Rol' }]} style={{width:'100%'}}>
                        <Select  onChange={e=>setSelectedRole(e)} placeholder={'Rol del Usuario'} size='large'>
                            {
                                roles ?
                                <>
                                {
                                    roles.map(elm=>{
                                        return <Select.Option value={elm.name} >{elm.name}</Select.Option>
                                    })
                                }
                                </>
                                :
                                null
                            }
                        </Select>
                    </Form.Item>
                    
                    <Form.Item label="Correo Electronico" name="email" rules={[{ required: true, message: 'Ingrese el Correo Electronico' }]} style={{width:'100%'}}>
                        <Input size="large" placeholder="Correo Electronico" onChange={e => setEmail(e.target.value)}/>
                    </Form.Item>

                    <Form.Item label="Contraseña" name="password" rules={[{ required: true, message: 'Ingrese la Razon Social' }]} style={{width:'100%'}}>
                        <Input size="large" placeholder="Contraseña" onChange={e => setPassword(e.target.value)}  type='password' />
                    </Form.Item>
                    
                    <Form.Item label="Confirmar Contraseña" name="oficiallName" 
                        rules={[
                            { required: true, message: 'Confirme su Contraseña' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(new Error('Las Contraseñas Ingresadas no Coinciden!'));
                                },
                              }),
                        ]} 
                        style={{width:'100%'}}>
                        <Input size="large" placeholder="Confirmar Contraseña" type='password'/>
                    </Form.Item>
                    <Flex align="middle" justify='space-between' >
                        <Form.Item>
                        <Button type="primary" htmlType="submit" style={{background:'#47b5bd'}} loading={isLoading}>
                            Registrar Usuario
                        </Button>
                        </Form.Item>
                        <Form.Item>
                        <Button type="primary" style={{background:'#ff4d4f'}} onClick={()=>setShow(false)}>
                            Cancelar
                        </Button>
                        </Form.Item>
                    </Flex>
                </Form>
            </Flex>
      </Modal>
    </>
  )
}

export default RegisterAdminUserModal
