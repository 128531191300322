import React, { useEffect, useState } from 'react'
import backendApi from '../api/backendApi';
import { Button, Divider, Flex, Form, GetProp, Image, Input, Modal, Select, Typography, Upload, UploadFile, UploadProps, notification   } from "antd";
import sirumatekLogo from '../assets/sirumatekLogo.png';
import {PlusOutlined, FrownOutlined} from '@ant-design/icons';
import { GetRolesRequest, Role } from '../interfaces/AppInterfaces';
import { useNavigate } from 'react-router-dom';

interface Props{
    isVisible:boolean;
    isUserRegister: (val:boolean)=>{}
}

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];



const   RegisterUserModal = ({isVisible, isUserRegister}:Props) => {
    
    
    const [permissions, setPermissions] = useState();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [roles, setRoles] = useState<Role[]>();
    const [show, setShow] = useState<boolean>(isVisible);
    const [tipoRif, setTipoRif] = useState<string>('J');
    const [docTypeUser, setdocTypeUser] = useState<string>('J');
    const [logo, setLogo] = useState<UploadFile[]>();
    const [copiaRif, setCopiaRif] = useState<UploadFile[]>();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [registerNewDigitalEmiterForm] = Form.useForm();
    const navigate = useNavigate();

    const [name, setName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [docId, setDocId] = useState<string>('');
    const [mobilePhone, setMobilePhone] = useState<string>('');
    const [rifNumber, setRifNumber] = useState<string>('');
    const [taxAddress, setTaxAddress] = useState<string>('');
    const [comercialName, setComercialName] = useState<string>('');
    const [officialName, setOfficialName] = useState<string>('');
    const [taxPhone, setTaxPhone] = useState<string>('');

    const [api, contextHolder] = notification.useNotification();

    const openSuccesNotification = () => {
        api.open({
          message: 'Se Registro Con Exito el Emisor',
          description:
            'Se ha registrado con exito el emisor',
          icon: <PlusOutlined style={{ color: '#47b5bd' }} />,
          onClick: ()=>navigate(0)
        });
      };
    
    const openErrorNotification = (err:JSON) => {
        console.log(err);
        const errors = JSON.stringify(err);
        api.open({
          message: 'No se Registro el Emisor',
          description:
            'Ha ocurrido un Error:  '+errors,
          icon: <FrownOutlined style={{ color: '#47b5bd' }} />,
        });
      };
    


   

    const getRoles = async () => {
        const response = await backendApi.get<GetRolesRequest>('/getRoles');
        setRoles(response.data.data[0]);
    }

    const saveUser = async () => {
        
        const form = new FormData();
        form.append("name", name+' '+lastName);
        form.append("email", email);
        form.append("password", password);
        form.append("doc_id", docTypeUser+docId);
        form.append("mobile_phone", mobilePhone);
        form.append("role", "Emisor");
        form.append("rif_number", tipoRif+'-'+rifNumber);
        form.append("tax_address", taxAddress);
        form.append("comercial_name", comercialName);
        form.append("official_name", officialName);
        form.append("tax_phone", taxPhone);
        setIsLoading(true);
        if (copiaRif) {
            
            form.append("rif_copy", copiaRif[0].originFileObj as any );
        

        }
        if (logo) {
            form.append("logo",  logo[0].originFileObj as any);
           // console.log(logo[0]);
        }
       
      
      
        const response = await backendApi.post('/registerEmiterUser', form).then(res=>{
            openSuccesNotification();
            setIsLoading(false);
            setShow(false);
            registerNewDigitalEmiterForm.resetFields();

        }).catch(err=>{
            
            setIsLoading(false);
            openErrorNotification(err.response.data.errors);

        });
    }
    //@ts-ignore
    const frontendUpload=(file,fileList)=>{
        // parseTheFile (file);
        //@ts-ignore   
        console.log(file);
        console.log(fileList);
     }
     
     const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj as FileType);
        }
    
        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
      };

        const prefixSelector = (
            <Form.Item name="prefix" noStyle>
            <Select style={{ width: 70 }} onChange={e=>setTipoRif(e)} defaultValue={"J"}>
                <Select.Option value="V" >V</Select.Option>
                <Select.Option value="E">E</Select.Option>
                <Select.Option value="J">J</Select.Option>
                <Select.Option value="P">P</Select.Option>
                <Select.Option value="G">G</Select.Option>
            </Select>
            </Form.Item>
        );

        const prefixSelectorUser = (
            <Form.Item name="prefixUser" noStyle>
            <Select style={{ width: 70 }} onChange={e=>setdocTypeUser(e)} defaultValue={"J"}>
                <Select.Option value="V" >V</Select.Option>
                <Select.Option value="E">E</Select.Option>
                <Select.Option value="J">J</Select.Option>
                <Select.Option value="P">P</Select.Option>
                <Select.Option value="G">G</Select.Option>
            </Select>
            </Form.Item>
        );

      const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Subir Archivo</div>
        </button>
      );
      

      const getBase64 = (file: FileType): Promise<string> =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result as string);
          reader.onerror = (error) => reject(error);
        });

        const handleChangeLogo: UploadProps['onChange'] = ({ fileList: newFileList }) => setLogo(newFileList);
        const handleChangeCopiaRif: UploadProps['onChange'] = ({ fileList: newFileList }) => setCopiaRif(newFileList);
    
        useEffect(() => {
            getRoles()
           
        }, [])

        useEffect(() => {
            setShow(isVisible);
        }, [isVisible])
  return (
    <>
        {contextHolder}
        <Modal open={show} footer={null}  style={{ top: 20 }} width={900} closable={false}>
            <Flex align="middle" justify="center" vertical>
                <Flex align="middle" justify="center" vertical>
                    <img
                        src={sirumatekLogo}
                        width={238}
                        height={42}
                        alt="Conpany Logo"
                        style={{alignSelf:'center'}}
                        />
                    <Typography.Text style={{fontSize:14, color: 'rgba(0, 0, 0, 0.4)',alignSelf:'center',marginTop:5}}>Sistema de Imprenta Digital </Typography.Text>
                </Flex>
                    <Typography.Text style={{fontSize:18, fontWeight:'bold'}}>Registrar Emisor</Typography.Text>  
                    <Divider/>   
            </Flex>
            <Flex align="middle" justify="center" vertical>
                <Form layout='vertical' onFinish={saveUser} style={{width:'100%'}} disabled={isLoading} form={registerNewDigitalEmiterForm}>
                    
                    
                    <Form.Item label="Nombre" name="name" rules={[{ required: true, message: 'Ingrese el Nombre del Responsable' }]} style={{width:'100%'}}>
                        <Input size="large" placeholder="Nombre del Responsable" onChange={e => setName(e.target.value)}/>
                    </Form.Item>

                    <Form.Item label="Apellido" name="lastName" rules={[{ required: true, message: 'Ingrese el Apellido del Responsable' }]} style={{width:'100%'}}>
                        <Input size="large" placeholder="Apellido del Responsable" onChange={e => setLastName(e.target.value)}/>
                    </Form.Item>

                    <Form.Item label="Documento de Identidad" name="cedula" rules={[{ required: true, message: 'Ingrese el Rif' }]} style={{width:'100%'}}>
                        <Input addonBefore={prefixSelectorUser} size="large" placeholder="Documento de Identidad del Responsable" onChange={e => setDocId(e.target.value)} pattern='^[0-9]*$' maxLength={10}/>
                    </Form.Item>

                
                    <Form.Item label="Telefono" name="mobilePhone" rules={[{ required: true, message: 'Ingrese el Telefono de Contacto del Responsable' }]} style={{width:'100%'}}>
                        <Input size="large" placeholder="Telefono de Contacto del Responsable" onChange={e => setMobilePhone(e.target.value)} pattern='^[0-9]*$' maxLength={11}/>
                    </Form.Item>

                    <Form.Item label="Correo Electronico" name="email" rules={[{ required: true, message: 'Ingrese el Correo Electronico' }]} style={{width:'100%'}}>
                        <Input size="large" placeholder="Correo Electronico" onChange={e => setEmail(e.target.value)}/>
                    </Form.Item>

                    <Form.Item label="Nombre Comercial" name="comercialName" rules={[{ required: true, message: 'Ingrese el Nombre Comercial' }]} style={{width:'100%'}}>
                        <Input size="large" placeholder="Nombre Comercial" onChange={e => setComercialName(e.target.value)}/>
                    </Form.Item>

                    <Form.Item label="Razon Social" name="oficialName" rules={[{ required: true, message: 'Ingrese la Razon Social' }]} style={{width:'100%'}}>
                        <Input size="large" placeholder="Razon Social" onChange={e => setOfficialName(e.target.value)}/>
                    </Form.Item>

                    <Form.Item label="Rif" name="rif" rules={[{ required: true, message: 'Ingrese el Rif' }]} style={{width:'100%'}}>
                        <Input addonBefore={prefixSelector} size="large" placeholder="RIF" onChange={e => setRifNumber(e.target.value)} pattern='^[0-9]*$' maxLength={10}/>
                    </Form.Item>

                    <Form.Item label="Direccion Fiscal" name="taxAddres" rules={[{ required: true, message: 'Ingrese La direccion Fiscal' }]} style={{width:'100%'}}>
                        <Input size="large" placeholder="Direccion Fiscal" onChange={e => setTaxAddress(e.target.value)}/>
                    </Form.Item>

                    <Form.Item label="Telefono Oficial" name="taxPhone" rules={[{ required: true, message: 'Ingrese el Telefono Oficial' }]} style={{width:'100%'}}>
                        <Input size="large" placeholder="Telefono Oficial" onChange={e => setTaxPhone(e.target.value)} pattern='^[0-9]*$' maxLength={11}/>
                    </Form.Item>

                    <Form.Item label="Logotipo" name="logo" >
                        <Upload 
                            listType={'picture-card'} 
                            onPreview={handlePreview} 
                            onChange={handleChangeLogo} 
                            fileList={logo}
                            maxCount={1}
                            multiple={false}
                            beforeUpload={(file)=>{
                                console.log(file);
                                
                                setLogo([file])
                                return false
                            }}
                            >
                            {
                               
                                uploadButton
                            }
                        </Upload>
                        {previewImage && (
                            <Image
                                wrapperStyle={{ display: 'none' }}
                                preview={{
                                    visible: previewOpen,
                                    onVisibleChange: (visible) => setPreviewOpen(visible),
                                    afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                }}
                                src={previewImage}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="Copia Rif" name="rifCopy" >
                        <Upload 
                            listType={'picture-card'} 
                            onPreview={handlePreview} 
                            onChange={handleChangeCopiaRif} 
                            fileList={copiaRif} 
                            maxCount={1}
                            beforeUpload={(file)=>{
                                console.log(file);
                                
                                setCopiaRif([file])
                                return false
                            }}>
                            {
                               uploadButton
                            }
                        </Upload>
                        {previewImage && (
                            <Image
                                wrapperStyle={{ display: 'none' }}
                                preview={{
                                    visible: previewOpen,
                                    onVisibleChange: (visible) => setPreviewOpen(visible),
                                    afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                }}
                                src={previewImage}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="Contraseña" name="password" rules={[{ required: true, message: 'Ingrese la Razon Social' }]} style={{width:'100%'}}>
                        <Input size="large" placeholder="Contraseña" onChange={e => setPassword(e.target.value)}  type='password' />
                    </Form.Item>
                    <Form.Item label="Confirmar Contraseña" name="oficiallName" 
                        rules={[
                            { required: true, message: 'Confirme su Contraseña' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(new Error('Las Contraseñas Ingresadas no Coinciden!'));
                                },
                              }),
                        ]} 
                        style={{width:'100%'}}>
                        <Input size="large" placeholder="Confirmar Contraseña" type='password'/>
                    </Form.Item>
                    <Flex align="middle" justify='space-between' >
                        <Form.Item>
                        <Button type="primary" htmlType="submit" style={{background:'#47b5bd'}} loading={isLoading}>
                            Registrar Emisor
                        </Button>
                        </Form.Item>
                        <Form.Item>
                        <Button type="primary" style={{background:'#ff4d4f'}} onClick={()=>setShow(false)}>
                            Cancelar
                        </Button>
                        </Form.Item>
                    </Flex>
                </Form>
            </Flex>
      </Modal>
    </>
  )
}

export default RegisterUserModal
