import React, { useEffect, useState } from 'react'
import { Button, Card, Divider, Flex,  Form,  Input,  Modal,  Space, Table, Tag, Typography } from 'antd'
import Link from 'antd/es/typography/Link'
import backendApi from '../api/backendApi'
import { DigitalEmiter, GetDigitalEmitersRequest, User } from '../interfaces/AppInterfaces'
import {PlusOutlined} from '@ant-design/icons';
import RegisterUserModal from '../components/RegisterUserModal'
import { useNavigate} from 'react-router-dom'
import sirumatekLogo from '../assets/sirumatekLogo.png';

const DigitalEmitersPage = () => {
    const [ditalEmiters, setDigitalEmiters] = useState<DigitalEmiter[]>()
    const [loading, setLoading] = useState(true)
    const [totalDocs, setTotalDocs] = useState(0)
    const [totalAnuls, setTotalAnuls] = useState(0)
    const [registerUserModal, setRegisterUserModal] = useState<boolean>(false);
    const [isUserRegisterModal, setIsUserRegisterModal] = useState<boolean>(false);
    const navigate = useNavigate()
    const [activateUserModal, setActivateUserModal] = useState<boolean>(false);
    const [resetPassworUserModal, setResetPasswordUserModal] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    const [selectedUser, setSelectedUser] = useState<User>()
    const [deactivateModal, setDeactivateModal] = useState<boolean>(false);

    const goToDetails = (id:any)=>{
        navigate('/userDetail/'+id,{replace:true})
    }
    const columns =[
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Documento Identidad',
            dataIndex: 'doc_id',
            key: 'doc_id',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text:number) =>{
                switch (text) {
                    case 1:
                        return <Tag color='green'>Activo</Tag>
                        break;
                    case 2:
                        return <Tag color='red'>Inactivo</Tag>
                        break
                    default:
                        return <Tag color='grey'>N/A</Tag>
                        break;
                }
            } 
        },
        {
            title: 'Facturas',
            dataIndex: 'digitalBillCount',
            key: 'digitalBillCount',
        },
        {
            title: 'Notas de Credito',
            dataIndex: 'digitalCreditNoteCount',
            key: 'digitalCreditNoteCount',
        },
        {
            title: 'Notas de Debito',
            dataIndex: 'digitalDebitNoteCount',
            key: 'digitalDebitNoteCount',
        },
        {
            title: 'Ordenes de Despacho',
            dataIndex: 'digitalShippingOrderCount',
            key: 'digitalShippingOrderCount',
        },
        {
            title: 'Contacto',
            dataIndex: 'mobile_phone',
            key: 'mobile_phone',
        },     
        {
            title: 'Ultimo Inicio de Sesion',
            dataIndex: 'last_login',
            key: 'last_login',
            render: (text:string)=>{
                if (text!== null) {
                    return text;
                }
                else{
                    return 'No ha Iniciado Sesion'
                }
            }
        },    
        {
            title: 'Action',
            key: 'action',
            //@ts-ignore
            render: (_, record) => (
              <Space size="middle">
                <Link style={{color:'red'}} onClick={()=>{deactivateUserModal(record)}}>Inhabilitar</Link>
                <Link onClick={()=>goToDetails(record.id)}>Ver {record.name}</Link>
                <Link onClick={()=>changePasswordUser(record.id)}>Reestablecer Contraseña</Link>
              </Space>
            ),
        },
    ]


    const getDigitalEmiters = async ()=>{
        const apiCall = await backendApi.get<GetDigitalEmitersRequest>('/getDigitalEmiters');
        if (apiCall.data.data) {
            setDigitalEmiters(apiCall.data.data[0]);
            getCardData(apiCall.data.data[0]);
        }
        setLoading(false);
    }
    const changePasswordUser = (user:User)=>{
        setSelectedUser(user)
        setResetPasswordUserModal(true)
    }

    const activateUser = (user:User)=>{
        setSelectedUser(user)
        setActivateUserModal(true)
    }

    const deactivateUserModal = (user:User)=>{
        setSelectedUser(user)
        setDeactivateModal(true)
    }
    const getCardData = (ditalEmiters:DigitalEmiter[]) =>{
        let totalDocs = 0;
        let totalAnuls = 0;
        
        ditalEmiters.map(elm=>{
            totalDocs += elm.digitalBillCount;
            totalDocs += elm.digitalCreditNoteCount;
            totalDocs += elm.digitalDebitNoteCount;
            totalDocs += elm.digitalShippingOrderCount;
            totalAnuls+= elm.digitalCreditNoteCount;
            totalAnuls+= elm.digitalDebitNoteCount;
        });
        
        
        setTotalDocs(totalDocs);
        setTotalAnuls(totalAnuls);
    }

    const registerDigitalEmiter = ()=>{
        return setRegisterUserModal(!registerUserModal)
        
    }

    useEffect(() => {
        getDigitalEmiters()
    }, [])

    useEffect(() => {
        console.log(isUserRegisterModal)
    }, [isUserRegisterModal])
    

  return (
    <Flex justify="center" vertical gap="middle">
        <Flex style={{width:'100%'}} justify='space-between' >
            <Typography.Title>Emisores Digitales</Typography.Title>
            <Button type="primary" style={{background:'#47b5bd', alignSelf:'center',width:200}} onClick={registerDigitalEmiter}>
                <PlusOutlined /> Nuevo Emisor
            </Button>
        </Flex>
        <Flex justify="center"gap="middle">
            <Flex gap="middle" justify="center" style={{ width:'100%'}}>
                <Card style={{  width: '40%', backgroundColor:'RGB(198, 249, 202, 0.8)'}} loading={loading} >
                    <Flex justify="center"gap="middle" vertical align='center'>
                        <Typography.Text style={{fontSize:15,  color:'#8c8c8c', fontWeight:'bold'}}>Emisores Digitales</Typography.Text>
                        <Typography.Text style={{fontSize:18, fontWeight:'bold' }}>{ditalEmiters?.length}</Typography.Text>
                    </Flex>
                </Card>
                <Card style={{  width: '40%', backgroundColor:'RGB(198, 249, 250, 0.5)' }} loading={loading} >
                    <Flex justify="center"gap="middle" vertical align='center'>
                        <Typography.Text style={{fontSize:15,  color:'#8c8c8c', fontWeight:'bold'}}>Documentos Transmitidos</Typography.Text>
                        <Typography.Text style={{fontSize:18, fontWeight:'bold' }}>{totalDocs}</Typography.Text>
                    </Flex>
                </Card>
                <Card style={{  width: '40%', backgroundColor:'RGB(198, 204, 250, 0.5)' }} loading={loading} >
                    <Flex justify="center"gap="middle" vertical align='center'>
                        <Typography.Text style={{fontSize:15,  color:'#8c8c8c', fontWeight:'bold'}}>Documentos Anulados</Typography.Text>
                        <Typography.Text style={{fontSize:18, fontWeight:'bold' }}>{totalAnuls}</Typography.Text>
                    </Flex>
                </Card>
            </Flex>    
        </Flex>
      <Flex justify="center" vertical gap="middle">
        <Table columns ={columns} dataSource={ditalEmiters} scroll={{ x: 1}} loading={loading}/>
        
      </Flex>
      <RegisterUserModal isVisible={registerUserModal} isUserRegister={()=>setIsUserRegisterModal}/>
      
      <Modal open={resetPassworUserModal} footer={null}  style={{ top: 20 }} width={900} closable={false}>
            <Flex align="middle" justify="center" vertical>
                <Flex align="middle" justify="center" vertical>
                    <img
                        src={sirumatekLogo}
                        width={238}
                        height={42}
                        alt="Conpany Logo"
                        style={{alignSelf:'center'}}
                        />
                    <Typography.Text style={{fontSize:14, color: 'rgba(0, 0, 0, 0.4)',alignSelf:'center',marginTop:5}}>Sistema de Imprenta Digital </Typography.Text>
                </Flex>
                <Typography.Text style={{fontSize:18, fontWeight:'bold'}}>Cambiar Contraseña</Typography.Text>  
                <Divider/>
                    <Form layout='vertical' style={{width:'100%'}} onFinish={()=>{}}>
                        <Flex align="middle" justify='center' style={{marginTop:5,marginBottom:30}}>
                            <Form.Item label="Contraseña" name="password" rules={[{ required: true, message: 'Ingrese la Contraseña' }]} style={{width:'100%'}}>
                                <Input size="large" placeholder="Contraseña" onChange={e => setPassword(e.target.value)}  type='password' />
                            </Form.Item>
                        </Flex>  
                        <Flex align="middle" justify='center' style={{marginTop:5,marginBottom:30}}>
                            <Form.Item label="Confirmar Contraseña" name="oficiallName" 
                                rules={[
                                    { required: true, message: 'Confirme su Contraseña' },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Las Contraseñas Ingresadas no Coinciden!'));
                                        },
                                    }),
                                ]} 
                                style={{width:'100%'}}>
                                <Input size="large" placeholder="Confirmar Contraseña" type='password'/>
                            </Form.Item>
                        </Flex>
                        <Flex align="middle" justify='space-between' >
                            <Form.Item>
                                <Button type="primary" htmlType="submit" style={{background:'#47b5bd'}}>
                                    Reestablecer Contraseña
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" style={{background:'#ff4d4f'}} onClick={()=>setResetPasswordUserModal(false)}>
                                    Cancelar
                                </Button>
                            </Form.Item>
                        </Flex>
                    </Form>
                </Flex>
      </Modal>
    </Flex>
  )
}

export default DigitalEmitersPage
